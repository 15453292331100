import * as React from "react";
import Container from "react-bootstrap/esm/Container";
import Nav from "react-bootstrap/esm/Nav";
import Navbar from "react-bootstrap/esm/Navbar";
import "bootstrap/dist/css/bootstrap.min.css";
import DrakezBrandImg from "../images/Drakez_OfficialLogo.png";
import DrakezBackground from "../images/Dragon_website_animation_loop.mp4";

// links
const discordLink = "https://discord.gg/jGaDfWJTqr";
const twitterLink = "https://twitter.com/DrakezNFT";
const openseaLink = "https://opensea.io/collection/drakez";

// styles
const main = {
  backgroundColor: 'black',
  width: '100vw',
  height: '100vh',
}

const parent = {
  position: 'fixed',
  left: '50%',
  transform: 'translate(-50%, -25%)',
  bottom: '0',
  paddingBottom: '50px',
}

const textStyle = {
  fontWeight: '400',
  fontSize: '2rem',
  fontFamily: 'PixelSplitter,monospace,sans-serif',
  color: 'rgb(209, 30, 29)',
}

const navbarItem = {
  marginRight: '50px',
}

const brand = {
  marginLeft: '25px',
}

const background = {
  position: 'fixed',
  left: '50%',
  top: '50%',
  transform: 'translate(-50%, -50%)',
  bottom: '10%',
  width: '100vw',
  height: '100vh',
  objectFit: 'cover',
}

// markup
const IndexPage = () => {
  return (
    <main style={main}>
      {/* Navbar */}
      <Navbar fixed="top" expand="lg">
        <Navbar.Brand style={brand}>
          <img
            alt=""
            src={DrakezBrandImg}
            width="160"
            height="120"
          />
        </Navbar.Brand>
        <Container>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse style={textStyle} id="basic-navbar-nav">
            <Nav className="me-auto">
              {/* Discord */}
              <Nav.Link style={{ ...textStyle, ...navbarItem }} href={discordLink} target="_blank">Discord</Nav.Link>
              {/* Twitter */}
              <Nav.Link style={{ ...textStyle, ...navbarItem }} href={twitterLink} target="_blank">Twitter</Nav.Link>
              {/* OpenSea */}
              <Nav.Link style={{ ...textStyle, ...navbarItem }} href={openseaLink} target="_blank">OpenSea</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      {/* Background */}
      <video style={background} autoPlay loop muted>
        <source src={DrakezBackground} type="video/mp4"/>
      </video>

      {/* Text */}
      <div style={parent}>
        <h1 style={textStyle}>Coming Soon</h1>
      </div>
    </main>
  )
}

export default IndexPage
